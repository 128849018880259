$warning-border-color: #eea236;
$warning-text-color: #e68700;

.property-integration-error {
    max-width: 500px;

    &.warning {
      border: 1px solid $warning-border-color;
      color: $warning-text-color;
    }
}

button.btn-facebook {
    min-width: 75%;
    margin: 0px;
    background: #3B5998;
    font-size: 12px;
}


button.btn-sm {
  margin: 0px;
  padding: 3px 6px;
  border-radius: 3px;
}

button.btn-md {
  margin: 14px 7px;
  padding: 8px 12px;
  border-radius: 3px;
}

button.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

button.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

button.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: $warning-border-color;
}

button.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a; 
}

.actionable {
  cursor: pointer;
}

.actionable:hover {
  opacity: 0.75;
}

.pull-right {
    float: right;
}

.mTop5 {
    margin-top: 5px;    
}

.mLeft10 {
    margin-left: 10px;
}

.max-width-500 {
    max-width: 500px;
}

.integration-info {

  .integration-info-title {
    font-weight: bold;
  }
}
