.property-photos-main {
    .photos-dialog-modal {
        z-index: 1;
    }

    .property-photos {

        width: 100%;
        display: inline-block;

        .image-file-input {
            display: none;
        }
        
        .property-photos-container {
            display: inline;
            z-index: -1;
        }

        .property-photos-container-single-wrap {
            display: inline-block;
            margin: 10px;
            float: left;
        }
        
        .property-photos-container-single {
            border-radius: 4px;

            width: 200px;
            height: 200px;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center; 
            position: relative;

            .file-drop {
                position: absolute;
                top: 0;
                right:0;
                height:100%;
                width:100%;

                .file-drop-target {
                    height:100%;
                    width:100%;
                }
            }
        }
        
        .property-photos-container-single-wrap .property-photos-container-single-file-drop {
            color: darkgrey;
            border: 1px solid;
            border-color: darkgrey; 
            display: table-caption;
        }

        .property-photos-container-single-file-drop .property-photos-container-single-file-drop-placeholder {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .property-photos-container-single-wrap .property-photos-container-single-file-drop:hover {
            cursor: pointer;
            background-color: whitesmoke;
        }

        .status-management-toolbar {

        }

        .property-photos-container-single-wrap .loading {
            opacity: 0.45;
        }

        .drag-hand {
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
        }

        .drag-hand:active {
            cursor: grabbing;
            cursor: -moz-grabbing;
            cursor: -webkit-grabbing;
        }

        .draggable.placeholder {
            opacity: 0.25;
        }

    }

    .btn-primary {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
    }

    .btn-success {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    }

    .btn-warning {
        color: #fff;
        background-color: #f0ad4e;
        border-color: #eea236;
    }

    .btn-danger {
        color: #fff;
        background-color: #d9534f;
        border-color: #d43f3a; 
    }

    .pull-right {
        float: right;
    } 

    div.btn-mock {
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        font: 11px system-ui;
        border: none;
        border-radius: 6px;
        outline: none;
        min-width: 0px;
        padding: 8px;
        margin: 5px;
    }
    
    button {
        min-width: 0px;
        padding: 8px;
        margin: 5px;
    }
}
