@import '~@knockrentals/knock-react/_knockReactColors';
@import '~@knockrentals/knock-react/_knockReactFlex';
@import '~@knockrentals/knock-react/_knockReactForms';

body, html, div#root {
  padding: 0;
  margin: 0;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  min-width: 900px;
  overflow: hidden;
  color: $knock-react-text-color;
}

.knock-admin-app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;

  .knock-admin-app-display-col {
    height: 100%;
  }

  .knock-admin-app-navbar-col {
    width: 155px;
    min-width: 155px;
  }

  .knock-admin-app-content-col {
    position: relative;
    flex-grow: 1;
    padding: 25px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
  }

  .wire-frame-table {
    border-collapse: collapse;

    td, th {
      border: 1px solid black;
      padding: 4px;
      text-align: left;
      white-space: nowrap;

      &.money-col {
        text-align: right;
      }
    }
  }

  hr {
    margin-top: 20px;
  }

  textarea {
    min-width: 500px;
    min-height: 100px;
  }

  pre {
    background-color: $knock-react-background-color;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid $knock-react-border-color;
  }
}

.login-page-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;

  .login-page-form {
    text-align: center;
    width: 450px;
  }
}

.sticky-table-first-column {
  table {
    table-layout: fixed !important;
  }

  table thead th {
    position: sticky !important;
    top: 0 !important;
  }

  table tbody th {
    position: relative !important;
  }

  table thead th:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 11 !important;
    background-color: #fff;
    width: 200px !important;
  }

  table tbody td:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 2 !important;
    background-color: #fff;
    word-break: break-all;
  }
}

.property-source-costs {
  table {
    button {
      margin: 0;
    }
  }
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.w-full {
  width: 100%;
}

.text-danger {
  color: #F44336;
}

.text-info {
  color: #3FA9F5;
}

.text-black {
  color: #000;
}
.text-dark {
  color: #1A202c
}
.text-light {
  color: #4a5568;
}
.text-lighter {
  color: #e2e8f0;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center !important;
}
