.facebook-integration-content {
  min-width: 400px;

  table {
      width: 100%;

      td:first-child {
          min-width: 230px;
      }

      .txt-secondary {
          margin-left: 5px;
          font-size: x-small;
      }
  }
  
  .row-null {
      text-align: center;
      padding: 7.5px;
  }

  .auth-error {
      color: #a94442;
      border-color: #ebccd1;
      border-radius: 5px;
      border: 1px solid;
      margin-top: 15px;
      padding: 7.5px;
      font-size: 12px;
  }

  .legal-disclaimer-wrapper {
    display: flex;
    padding-top: 20px;
    text-align: center;
  }
}

button.edit-facebook-button {
  margin-left: 0;
}

.add-integration-buttons {
  max-width: 1000px;

  .add-integration-button {
    width: 210px;
  }
}

span.txt-disclaimer {
  color: grey;
  font-size: x-small;
  font-style: italic;
  margin-right: 20px;
}